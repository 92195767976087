import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { Name } from "./name";
import { Phone } from "./phone";
import UserServices from "../../services/public-users";
import { AlertDialog } from "../dialogs";

const ISRAELI_PHONE_REGEX = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
const NUMBERS_REGEX = /[0-9]|\./;
const LETTERS = /^[a-zA-Zא-ת][a-zA-Zא-ת ]*$/;

export const ContactBox = ({ contactButtonText }) => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");

  const handleDialogClickOpen = (dedcription) => {
    setDescription(dedcription);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const onNameChanged = (event) => {
    if (!LETTERS.test(event.target.value) && event.target.value !== "") return;
    setErrors({});
    setName(event.target.value);
  };

  const onPhoneChanged = (event) => {
    if (!NUMBERS_REGEX.test(event.target.value) && event.target.value !== "")
      return;

    setErrors({});
    setPhoneNumber(event.target.value);
  };

  const handleSubmit = async () => {
    const errorsResult = validate(name, phoneNumber);

    if (!Object.values(errorsResult)?.every((value) => !value)) {
      setErrors(errorsResult);
      return;
    }

    try {
      await UserServices.save(name, phoneNumber);
      handleDialogClickOpen("פרטיך נשמרו במערכת, נציגנו יצרו איתך קשר.");
    } catch {
      handleDialogClickOpen("לצערינו יש תקלה, נסו בהמשך");
    }
  };

  return (
    <>
      <AlertDialog
        isOpen={open}
        close={handleDialogClose}
        description={description}
      />
      <Box className={classes.input}>
        <Name
          value={name}
          handleChange={onNameChanged}
          errorMessage={errors?.name}
        />
        <Phone
          handleChange={onPhoneChanged}
          value={phoneNumber}
          errorMessage={errors?.phoneNumber}
        />
      </Box>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={handleSubmit}
      >
        <Typography>{contactButtonText}</Typography>
      </Button>
    </>
  );
};

const validate = (name, phoneNumber) => {
  const errors = {};

  if (phoneNumber === "") {
    errors.phoneNumber = "יש למלא שדה זה";
  }
  if (name === "") {
    errors.name = "יש למלא שדה זה";
  }
  if (!ISRAELI_PHONE_REGEX.test(phoneNumber) && phoneNumber !== "") {
    errors.phoneNumber = "טלפון לא תקין";
  }

  return errors;
};

const useStyles = makeStyles({
  input: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    minHeight: "6vh",
    marginTop: "2vh",
    width: "55%",
  },
});
