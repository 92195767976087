import { useTheme, useMediaQuery } from "@material-ui/core";
import { DownSmScreen } from "./down-sm-screen";
import { UpSmScreen } from "./up-sm-screen";

const step1 = `בדיקת הסיכוי להחזר ופתיחת תיק לקוח`;
const step2 = `המתנה לקליטת ייפוי כוח מס הכנסה וביטוח לאומי`;
const step3 = `איסוף מסמכים והכנת דוחות ע"י יועצי המס שלנו`;
const step4 = `,הודעת החזר
יועצי המס שלנו יעדכנו אתכם בסכום ההחזר הצפוי`;
const step5 = `הגשת הדוחות למס הכנסה`;
const step6 = `קבלת ההחזר לחשבון הבנק שלכם`;

export const ReturnProcessDescriptions = () => {
  const theme = useTheme();
  const isDownSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const Component = isDownSmScreen ? DownSmScreen : UpSmScreen;
  return (
    <Component
      step1={step1}
      step2={step2}
      step3={step3}
      step4={step4}
      step5={step5}
      step6={step6}
    />
  );
};
