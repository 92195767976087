import React from "react";
import Paper from "@material-ui/core/Paper";
import { ConfirmBox } from "../confirm-box/confirm-box";
import { Eligibility } from "./answer-dialogs/eligibility";
import { Uneligibility } from "./answer-dialogs/uneligibility";
import makeStyles from "@material-ui/core/styles/makeStyles";

const questions = [
  { id: 0, text: 'האם את/ה שכיר/ה המשתכר/ת מעל 6000 ש"ח ברוטו?' },
  { id: 1, text: "האם את/ה מנהל/ת חשבון בנק תקין ללא עיקולים או פשיטות רגל?" },
  {
    id: 2,
    text: "האם משכת פנסיה, פיצויים או קרן השתלמות ב-6 השנים האחרונות ושילמת 35% מס?",
  },
  {
    id: 3,
    text: "האם החלפת מקומות עבודה או שעבדת בכמה מקומות במקביל ב-6 השנים האחרונות?",
  },
  { id: 4, text: "האם נולד לך ילד/ה ב-6 השנים האחרונות?" },
];

const answers = new Map();

export const EligibilityCheckQuestions = () => {
  const classes = useStyles();
  const [activeQuestion, setActiveQuestion] = React.useState(0);
  const [answer, setAnswer] = React.useState();

  const handleSelected = (id) => (answer) => {
    answers.set(id, answer);

    if (activeQuestion < questions.length - 1) {
      setActiveQuestion(activeQuestion + 1);
      return;
    }

    handleSubmit();
  };

  const handleSubmit = () => {
    if (answers.get(1) === false) {
      setAnswer(<Uneligibility />);
      return;
    }

    setAnswer(<Eligibility />);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <ConfirmBox
          question={questions[activeQuestion].text}
          onSelected={handleSelected(questions[activeQuestion].id)}
        />
      </Paper>
      {answer}
    </>
  );
};

const useStyles = makeStyles({
  paper: {
    backgroundColor: "rgb(255 153 0 / 20%)",
  },
});
