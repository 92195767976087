import { useToggle } from "../../hooks/use-toggle";
import { RetroAppBar } from "../retro-app-bar/retro-app-bar";
import { RetroSideBar } from "../retro-side-bar/retro-side-bar";

export const RetroMenu = () => {
  const [isOpen, open, close] = useToggle();

    return (<><RetroAppBar
    onDrawerOpen={open}
    isOpen={isOpen}
    onDrawerClose={close}
  />
  <RetroSideBar isOpen={isOpen} /></>
)}