import SuticaseImage from "./suticase.png";
import AttentionImage from "./attention.png";
import BirocraticImage from "./birocratic.png";
import ManWithLaptopImage from "./man-with-laptop.png";
import WomanWithPhonImage from "./woman-with-phon.png";
import PriceImage from "./price.png";
import TogetherImage from "./together.png";
import DogWithLaptopImage from "./dog-with-laptop.png";
import LogoImage from "./logo.png";
import HandShakeImage from "./hand-shake.png";
import ContractImage from "./contract.png";
import Arrow1Image from "./arrow1.png";
import Arrow2Image from "./arrow2.png";

export const Attention = ({ className }) => (
  <img src={AttentionImage} alt="לחיצת ידיים" className={className} />
);

export const Contract = ({ className }) => (
  <img src={ContractImage} alt="כתיבת מסמך" className={className} />
);

export const Birocratic = ({ className }) => (
  <img src={BirocraticImage} alt="שולח" className={className} />
);

export const ManWithLaptop = ({ className }) => (
  <img src={ManWithLaptopImage} alt="גבר עם לפטופ" className={className} />
);

export const WomanWithPhon = ({ className }) => (
  <img src={WomanWithPhonImage} alt="אשה עם פלאפון" className={className} />
);

export const Price = ({ className }) => (
  <img src={PriceImage} alt="עלות" className={className} />
);

export const Together = ({ className }) => (
  <img src={TogetherImage} alt="ביחד" className={className} />
);

export const DogWithLaptop = ({ className }) => (
  <img src={DogWithLaptopImage} alt="כלב עם לפטופ" className={className} />
);

export const Logo = ({ className }) => (
  <img src={LogoImage} alt="לוגו רטרו פלוס" className={className} />
);

export const Suticase = ({ className }) => (
  <img src={SuticaseImage} alt="מזוודה" className={className} />
);

export const HandShake = ({ className }) => (
  <img src={HandShakeImage} alt="לחיצת ידיים" className={className} />
);

export const Arrow2 = ({ className }) => (
  <img src={Arrow2Image} alt="חץ" className={className} />
);

export const Arrow1 = ({ className }) => (
  <img src={Arrow1Image} alt="חץ" className={className} />
);
