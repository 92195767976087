import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const Phone = ({ value, handleChange, errorMessage }) => {
  const classes = useStyles();

  return (
    <Grid container alignContent="center" alignItems="center">
      <Grid item sm={4} xs={12}>
        טלפון:
      </Grid>
      <Grid item sm={8} xs={12}>
        <TextField
          className={classes.input}
          error={!!errorMessage}
          helperText={errorMessage}
          id="phone"
          label="טלפון"
          type="number"
          value={value}
          onChange={handleChange}
          variant="standard"
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});
