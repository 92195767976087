import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const PaperCircle = ({ backgroundColor, size, children }) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.paper}
      style={{
        width: size,
        height: size,
        backgroundColor,
        padding: `calc(${size} / 6)`,
      }}
    >
      {children}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
