import { Description } from "./description";
import { Title } from "./title";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

export const TopicContent = ({
  description,
  title,
  titleClassName,
  descriptionClassName,
  className,
}) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.topic} ${className}`}>
      <Title className={titleClassName}>{title}</Title>
      <Description className={descriptionClassName}>{description}</Description>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  topic: {
    margin: "3vh",
  },
}));
