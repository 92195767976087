import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

export const OurReturnBox = ({ title = 0, children }) => {
  const classes = useStyles();

  return (
    <>
      <img
        src={"https://recommend-images.retroplus.co.il/" + children}
        alt="מסמך החזר"
        style={{ height: "100%", width: "100%" }}
      />
      <Box className={classes.box}>
        <Typography className={classes.text}>
          <b>{title}</b>
        </Typography>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  box: {
    position: "relative",
    top: "-60%",
    width: "35%",
    right: "28%",
    transform: "rotate(30deg)",
    border: "solid black",
    borderLeft: "none",
    borderRight: "none",
  },
  text: {
    direction: "ltr",
    whiteSpace: "pre-wrap",
    fontSize: "1.3rem",
    backgroundColor: "white",
  },
});
