import { BaseBox } from "./base-box";

const answer = {
  title: `אינך מתאים להחזר מס!!!​`,
  description: `לבדיקה מול נציג אנושי שלנו​
השאר פרטיך ונחזור אליך בהקדם.​
בדיקת הזכאות ללא עלות פתיחת תיק.​`,
};

export const Uneligibility = () => {
  return <BaseBox title={answer.title} description={answer.description} />;
};
