import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const RecommendBox = ({ title, description, picture }) => {
  const classes = useStyles();

  return (
    <>
      <img
        src={"https://recommend-images.retroplus.co.il/" + picture}
        alt="ממליץ"
        className={classes.picture}
      />
      <Typography className={`${classes.text} ${classes.title}`}>
        <b>{title}</b>
      </Typography>
      <Typography className={`${classes.text} ${classes.description}`}>
        {description}
      </Typography>
    </>
  );
};

const useStyles = makeStyles({
  picture: {
    width: "35%",
    borderRadius: "50%",
  },
  description: {
    whiteSpace: "pre-wrap",
    padding: "5% 2% 5% 2%",
  },
  title: {
    padding: "5% 2% 1% 2%",
    whiteSpace: "pre-wrap",
    fontSize: "1.2rem",
  },
  text: {
    direction: "ltr",
  },
});
