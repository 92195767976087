import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import { ContactBox } from "../../contact-input";

export const BaseBox = ({ title, description }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Box className={classes.box}>
        <Box>
          <Typography variant="h5">
            <b>{title}</b>
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </Box>
        <ContactBox contactButtonText="רטרו פלוס, דברו איתי" />
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  description: {
    whiteSpace: "pre-wrap",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    lineHeight: "0.75em",
  },
  box: {
    backgroundColor: theme.palette.secondary.light,
    padding: "6vh",
    borderRadius: "50%",
    border: `5px solid ${theme.palette.primary.main}`,
    width: "55vw",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      padding: "12vh",
    },
  },
  paper: {
    borderRadius: "50%",
    position: "absolute",
    top: "47vh",
    left: "50%",
    transform: "translate(-50%, 0)",
    [theme.breakpoints.between("xs", "sm")]: {
      top: "90vh",
    },
    [theme.breakpoints.down("xs")]: {
      top: "68vh",
    },
  },
}));
