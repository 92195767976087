import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Routes } from "../../App";
import { usePathnameLocation } from "../pathname-location-context";

export const RetroAppBar = ({ isOpen, onDrawerOpen, onDrawerClose }) => {
  const classes = useStyles();
  const showSideBar = useMediaQuery("(min-width:960px)");
  const [pathnameLocation, setPathnameLocation] = usePathnameLocation();

  const handleChange = (_, newValue) => {
    setPathnameLocation(newValue);
  };

  useEffect(() => {
    setPathnameLocation(window.location.pathname);
    // eslint-disable-next-line
  }, []);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {showSideBar ? (
          <Tabs
            variant="fullWidth"
            value={pathnameLocation}
            onChange={handleChange}
            indicatorColor="secondary"
          >
            <Tab
              label="דף הבית"
              component={Link}
              value={Routes.homePage}
              to={Routes.homePage}
              className={classes.menueText}
            />
            <Tab
              label="לבדיקת זכאות"
              component={Link}
              value={Routes.eligibilityCheck}
              to={Routes.eligibilityCheck}
              className={classes.menueText}
            />
            <Tab
              label="לקוחות ממליצים"
              component={Link}
              value={Routes.customersRecommend}
              to={Routes.customersRecommend}
              className={classes.menueText}
            />
            <Tab
              label="מאמר תוכן"
              component={Link}
              value={Routes.contentArticle}
              to={Routes.contentArticle}
              className={classes.menueText}
            />
            <Tab
              label="צור קשר"
              component={Link}
              to={Routes.contactUs}
              value={Routes.contactUs}
              className={classes.menueText}
            />
          </Tabs>
        ) : (
          <ClickAwayListener onClickAway={onDrawerClose}>
            <IconButton
              color="inherit"
              onClick={onDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, isOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </ClickAwayListener>
        )}
        <Typography variant="h5" noWrap className={classes.title}>
          <b>רטרו פלוס</b>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuText: {
    fontSize: "2rem",
  },
  title: {
    flexGrow: 0,
    marginLeft: "auto",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
}));
