import "./App.css";
import { RetroMenu, ImplementTheme } from "./components";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import {
  HomePage,
  EligibilityCheck,
  ContactUs,
  CustomersRecommend,
  ContentArticle,
} from "./screens";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Toolbar } from "@material-ui/core";
import { Logo } from "./images";
import { PathnameLocationContext } from "./components/pathname-location-context/pathname-location-context";
import { ScrollToTop } from "./components/scroll-to-top/scroll-to-top";

export const Routes = {
  homePage: "/home-page",
  eligibilityCheck: "/eligibility-check",
  contactUs: "/contact-us",
  customersRecommend: "/customers-recommend",
  contentArticle: "/content-article",
};

const App = () => {
  const classes = useStyles();

  return (
    <PathnameLocationContext>
      <ImplementTheme>
        <CssBaseline />
        <Router>
          <ScrollToTop />
          <RetroMenu />
          <main>
            <Toolbar />
            <Logo className={classes.logo} />
            <Switch>
              <Route path={Routes.homePage} component={HomePage} />
              <Route
                path={Routes.eligibilityCheck}
                component={EligibilityCheck}
              />
              <Route
                path={Routes.customersRecommend}
                component={CustomersRecommend}
              />
              <Route path={Routes.contentArticle} component={ContentArticle} />
              <Route path={Routes.contactUs} component={ContactUs} />
              <Route>
                <Redirect to={Routes.homePage} />
              </Route>
            </Switch>
          </main>
        </Router>
      </ImplementTheme>
    </PathnameLocationContext>
  );
};

export default App;

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: "30px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logo: {
    width: "30vh",
    float: "left",
    margin: "3vh 0 0 5vw",
    [theme.breakpoints.down("xs")]: {
      width: "35vw",
    },
  },
}));
