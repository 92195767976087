import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeIcon from "@material-ui/icons/Home";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import DescriptionIcon from "@material-ui/icons/Description";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Routes } from "../../App";
import { usePathnameLocation } from "../pathname-location-context";

export const RetroSideBar = ({ isOpen }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [, setPathnameLocation] = usePathnameLocation();

  const handleChange = (newValue) => () => {
    setPathnameLocation(newValue);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton style={{ marginRight: "auto" }}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem
          onClick={handleChange(Routes.homePage)}
          button
          component={Link}
          to={Routes.homePage}
        >
          <ListItemIcon>
            <HomeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"דף הבית"} />
        </ListItem>
        <ListItem
          button
          onClick={handleChange(Routes.eligibilityCheck)}
          component={Link}
          to={Routes.eligibilityCheck}
        >
          <ListItemIcon>
            <ContactSupportIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"לבדיקת זכאות"} />
        </ListItem>
        <ListItem
          button
          onClick={handleChange(Routes.customersRecommend)}
          component={Link}
          to={Routes.customersRecommend}
        >
          <ListItemIcon>
            <ThumbUpIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"לקוחות ממליצים"} />
        </ListItem>
        <ListItem
          button
          onClick={handleChange(Routes.contentArticle)}
          component={Link}
          to={Routes.contentArticle}
        >
          <ListItemIcon>
            <DescriptionIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"מאמר תוכן"} />
        </ListItem>
        <ListItem
          button
          onClick={handleChange(Routes.contactUs)}
          component={Link}
          to={Routes.contactUs}
        >
          <ListItemIcon>
            <PermPhoneMsgIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"צור קשר"} />
        </ListItem>
      </List>
    </Drawer>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}));
