import { Grid, TextField } from "@material-ui/core";

export const Name = ({ value, handleChange, errorMessage }) => {
  return (
    <Grid container alignContent="center" alignItems="center">
      <Grid item sm={4} xs={12}>
        שם מלא:
      </Grid>
      <Grid item sm={8} xs={12}>
        <TextField
          type="text"
          maxLength="50"
          error={!!errorMessage}
          helperText={errorMessage}
          autoFocus
          id="name"
          label="שם"
          value={value}
          onChange={handleChange}
          variant="standard"
        />
      </Grid>
    </Grid>
  );
};
