import {
  Container,
  Typography,
  Grid,
  Divider,
  Box,
  makeStyles,
} from "@material-ui/core";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FaxIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import { WomanWithPhon } from "../images";
import { ContactBox } from "../components/contact-input";
import backgroundImage1 from "../images/background-picture4.jpg";

const contactProperties = {
  title: "טוב להיות בקשר",
  properties: [
    { id: 1, title: "טלפון:", text: "079-5555591", icon: <LocalPhoneIcon /> },
    { id: 2, title: "ווטסאפ:", text: "058-6603050", icon: <WhatsAppIcon /> },
    { id: 3, title: "פקס:", text: "079-6973660", icon: <FaxIcon /> },
    {
      id: 4,
      title: "מייל:",
      text: "retroptax@gmail.com",
      icon: <EmailIcon />,
    },
  ],
};

export const ContactUs = () => {
  const classes = useStyles();

  return (
    <Box className={classes.background1}>
      <Container className={classes.main} component="div" maxWidth="sm">
        <WomanWithPhon className={classes.womanWithPhon} />
        <Typography
          className={`${classes.title} ${classes.textTitle}`}
          variant="h2"
        >
          <b>{`צרו איתנו קשר`}</b>
        </Typography>
        <Typography>הכי טוב להשאיר לנו פרטים למענה מהיר.</Typography>
        <ContactBox contactButtonText="צרו איתי קשר" />
      </Container>
      <Container className={classes.properties} component="div" maxWidth="lg">
        <Divider className={classes.divider} />
        <Typography variant="h6">
          <b>{contactProperties.title}</b>
        </Typography>
        <Grid container justifyContent="center">
          {contactProperties.properties.map((item) => (
            <Grid item sm={3} className={classes.grid} key={item.id}>
              {item.icon}
              <Typography>{item.text}</Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  properties: {
    textAlign: "center",
    whiteSpace: "pre-wrap",
    position: "relative",
  },
  background1: {
    backgroundImage: `linear-gradient(rgb(255 255 255 / 40%), rgb(255 255 255 / 30%)), url(${backgroundImage1})`,
    backgroundSize: "cover",
    minHeight: "calc(100vh - 65px)",
    paddingTop: "17vh",
  },
  main: {
    whiteSpace: "pre-wrap",
    textAlign: "center",
    marginBottom: "8vh",
  },
  womanWithPhon: {
    position: "absolute",
    top: "16vh",
    left: "4vw",
    width: "55vh",
    zIndex: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "60vw",
    },
  },
  title: {
    marginBottom: "3vh",
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "20vw",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "42vh",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "42vw",
    },
  },
  subTitle: {
    marginLeft: "330px",
    marginTop: "7.5rem",
  },
  textTitle: {
    color: theme.palette.primary.main,
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
  },
  topic: {
    marginTop: "19vh",
  },
  grid: {
    padding: "1vh",
  },
  divider: {
    margin: "0 10vw 2vh 10vw",
    backgroundColor: theme.palette.primary.main,
    height: "7px",
  },
}));
