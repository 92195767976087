import { Container, Box } from "@material-ui/core";
import { Description } from "../components/custom-components/description";
import { makeStyles } from "@material-ui/core/styles";
import { Contract } from "../images";
import { Title } from "../components/custom-components/title";
import backgroundImage1 from "../images/background-picture5.jpg";

const articleTitle = `למה שלא אגיש לבד החזר מס?
למה שלא אפנה לחברה הזולה ביותר להחזרי מס?`;
const articlePart1 = `תמיד כשעולה השאלה החזרי מס איפה כדאי?​
נכנסים לדילמה מסוימת, מצד אחד, הרצון שלנו הוא תמיד לשלם את העמלה הנמוכה ביותר בשוק או לא לשלם בכלל. מצד שני כמובן שיש חשיבות רבה גם למקצועיות, לשירות ולאמינות.​
אז בואו נעשה קצת סדר בדברים:​
"הזול הרבה פעמים עולה ביוקר" - פעמים רבות אנשים מעדיפים לחסוך לעצמם את עמלת השירות להחזרי מס, הם משיגים את כל המסמכים לבד: 106 ל-6 שנים אחרונות, גימלאות מביטוח לאומי, משיכות של תגמולים או פיצויים, ביטוחי חיים, תרומות, אישור תושב, אישור זכאות לתואר, אישור ניהול חשבון או צילום צ'ק מבוטל ועוד.​
אחרי מאמץ ועבודה מייגעת, הם ניגשים למשרדי מס הכנסה, ממלאים את הטפסים ומגישים לפקיד קבלת קהל במס הכנסה או לחליפין סורקים את כל המסמכים ומגישים באופן מקוון. ​
אחרי מאמץ כה גדול הם מצפים לקבל החזר ש"יצדיק את העבודה שלהם" אך בפועל פעמים רבות, מתקבל החזר זעום לחשבון הבנק או לחליפין במקרים מסוימים מס הכנסה מוציא שומת חוב ודורש לשלם את החוב באופן מידי.​
אופציה שניה אפשרית היא לפנות לחברת החזרי מס הגובה את העמלה הנמוכה ביותר בשוק, העמלה יכולה להיות נמוכה מאוד ויכולה להסכם ב-5% + מע"מ.
השאלה מה מקבלים במחיר זה? ​
פעמים רבות חברות מהסוג הזה,  נותנות לכם לעשות את  כל העבודה של השגת המסמכים וטיפול בניירת, החישוב שם לא תמיד נעשה בצורה מקצועית, זמן הטיפול יכול להיות ארוך ומייגע והתוצאה יכולה להיות דומה לתוצאה של "אם הייתם מגישים לבד", החזר קטן וזעום או במקרים מסויימים הסתבכות מול רשויות המס.​​`;

const boldArticlePart2 = `אז ככה, בבדיקת החזר מס אצלנו, בתור מייצגים מורשים יש לנו את היכולת להוציא עבורכם 106 ל-6 שנים האחרונות, גימלאות ודו"ח מעסיקים מביטוח לאומי, כך נחסוך לכם הרבה יגע ועמל בהשגת טפסים אלו. ​
אנחנו מבצעים בדיקה 100% מקצועית של התיק שלכם, על ידי יועצי מס בלבד. הבדיקה שלנו נעשית מראש כך שלא יהיו לכם "הפתעות" בדרך. מראש תדעו את סכום ההחזר הצפוי, ושאין לכם חובות במס הכנסה. ​
לכל אורך הדרך, תקבלו ליווי מקצועי של יועץ מס, כשהמטרה היא להוציא עבורכם את ההחזר הגבוה ביותר שניתן. ​

תמיד שיחת הסיום, בה נודיע לכם על החזר המס תבוצע ע"י יועץ מס, בהזדמנות זו תוכלו לשאול את כל השאלות ולקבל מענה מקצועי והסבר לבדיקת התיק שלכם. ​
אצלנו מלבד מענה טלפוני, תמיד תוכלו לפנות אלינו גם בווצאפ, ולקבל מענה ושירות מהיר ויעיל.​
הבדיקה הראשונית אצלנו היא ללא פתיחת תיק, כך שבמידה ואינכם זכאים להחזר מס לא תשלמו כלל. רק במידה ואתם זכאים ומקבלים את ההחזר תשלמו שכר טירחה.​`;

const articlePart3 = `שימו לב, לגבי חובות, רבים וטובים חושבים מכיוון שתמיד היו שכירים אין להם בוודאות חובות במס הכנסה. אז לא תמיד זה נכון, גם  החלפות עבודה, תיאום מס לא מדוייק או אפילו טעות של חשבת השכר במשכורת שלכם יכולה ליצור חובות.​
אל דאגה, גילוי מוקדם של מצב כזה יחסוך לכם הרבה כסף, כמובן שנעדכן אתכם, ולא נגיש עבורכם את הבקשה להחזר מס כדי שלא תצטרכו ללכת ולשלם את החובות. ברור כי גם על חובות מסוג זה יש התיישנות ואחרי מס' שנים הם פשוט נמחקים באופן אוטומטי.​`;

const boldArticlePart4 = `ולסיכום: ​
כשאתם מחליטים לגשת לתהליך של החזר מס, פנו תמיד למייצגים מורשים המתמחים בהחזרי מס לשכירים שיעשו לכם את העבודה והבדיקה מראש.​`;

export const ContentArticle = () => {
  const classes = useStyles();
  return (
    <Box className={classes.background1}>
      <Container className={classes.main} maxWidth="md">
        <Contract className={classes.womanWithPhon} />
        <Container className={classes.main} maxWidth="sm">
          <Title className={classes.title}>{articleTitle}</Title>
        </Container>
        <Description className={classes.description}>
          {articlePart1}
        </Description>
        <Description className={classes.description}>
          <b>{boldArticlePart2}</b>
        </Description>
        <Description className={classes.description}>
          {articlePart3}
        </Description>
        <Description className={classes.description}>
          <b>{boldArticlePart4}</b>
        </Description>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    whiteSpace: "pre-wrap",
    paddingTop: "10vh",
    marginBottom: "3vh",
  },
  background1: {
    backgroundImage: `linear-gradient(rgb(255 255 255 / 80%), rgb(255 255 255 / 80%)), url(${backgroundImage1})`,
    backgroundSize: "cover",
    minHeight: "calc(100vh - 65px)",
    paddingBottom: "3vh",
  },
  description: {
    marginLeft: "3vh",
    marginRight: "3vh",
  },
  title: {
    textAlign: "center",
    padding: "15vh 0vw 0vh 2.5vw",
    [theme.breakpoints.down("sm")]: {
      marginTop: "22vw",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "20vh",
    },
  },
  womanWithPhon: {
    position: "absolute",
    top: "16vh",
    left: "4vw",
    width: "55vh",
    zIndex: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "60vw",
    },
  },
}));
