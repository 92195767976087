import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";

export const ConfirmBox = ({ question, onSelected }) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <div className={classes.question}>{question}</div>
      <ButtonGroup
        size="large"
        variant="contained"
        className={classes.buttonGroup}
      >
        <Button
          className={`${classes.button1} ${classes.button}`}
          color="primary"
          onClick={() => onSelected(true)}
        >
          כן
        </Button>
        <Button
          className={`${classes.button2} ${classes.button}`}
          color="primary"
          onClick={() => onSelected(false)}
        >
          לא
        </Button>
      </ButtonGroup>
    </Box>
  );
};

const useStyles = makeStyles({
  box: {
    padding: "4vh 3vw 4vh 3vw",
  },
  question: {
    paddingBottom: "5vh",
    minHeight: "calc(5vh + 2.86rem)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  space: {
    width: "10vw",
    border: "none !important",
  },
  button: {
    width: "9vw",
    border: "none !important",
    borderRadius: "5px !important",
  },
  button1: {
    marginRight: "5%",
  },
  button2: {
    marginLeft: "5%",
  },
  buttonGroup: {
    boxShadow: "none",
  },
});
