import axios from "axios";
import { serverUrl } from "./config";

const uri = serverUrl + "/public/recommend/";

const fetchAll = async () => {
  const { data } = await axios.get(uri);

  return data;
};

const exports = { fetchAll };
export default exports;
