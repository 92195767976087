import { PaperCircle } from "../paper-circle";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { Arrow1 as Arrow } from "../../images";

export const DownSmScreen = ({ step1, step2, step3, step4, step5, step6 }) => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <PaperCircle size={"31vh"}>
        <Typography variant="body1" className={classes.text}>
          {step1}
        </Typography>
      </PaperCircle>
      <Arrow className={classes.arrow} />
      <PaperCircle size={"32vh"}>
        <Typography variant="body1" className={classes.text}>
          {step2}
        </Typography>
      </PaperCircle>
      <Arrow className={classes.arrow} />
      <PaperCircle size={"35vh"}>
        <Typography variant="body1" className={classes.text}>
          {step3}
        </Typography>
      </PaperCircle>
      <Arrow className={classes.arrow} />
      <PaperCircle size={"37vh"}>
        <Typography variant="body1" className={classes.text}>
          {step4}
        </Typography>
      </PaperCircle>
      <Arrow className={classes.arrow} />
      <PaperCircle size={"30vh"}>
        <Typography variant="body1" className={classes.text}>
          {step5}
        </Typography>
      </PaperCircle>
      <Arrow className={classes.arrow} />
      <PaperCircle size={"40vh"}>
        <Typography variant="h6" className={classes.text}>
          <b>{step6}</b>
        </Typography>
      </PaperCircle>
    </Box>
  );
};

const useStyles = makeStyles({
  arrow: {
    width: "15%",
    margin: "4vh",
    height: "100%",
    transform: "rotate(-304deg)",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    padding: "0vh 3vw 3vh 3vw",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    direction: "rtl",
  },
});
