import {
  Container,
  makeStyles,
  Typography,
  Grid,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CustomCard } from "../components/card";
import { Suticase, Birocratic, Attention, Price, Together } from "../images";
import backgroundImage1 from "../images/background-picture1.jpg";
import backgroundImage2 from "../images/background-picture3.jpg";
import { Routes } from "../App";
import { useHistory } from "react-router-dom";
import { usePathnameLocation } from "../components/pathname-location-context";
import { ReturnProcessDescriptions } from "../components/return-process-descriptions";

const title = `רטרו פלוס
          החזרי מס`;
const xsTitle = `רטרו פלוס
החזרי מס`;

const whoIsUs = {
  title: "מי אנחנו?",
  description: `רטרו פלוס הינו משרד ליעוץ מס המספק שירותים איכותיים לעצמאיים ולשכירים. ​
  במשרדנו נעזור לכם למצות את זכויותיכם בתחום החזרי מס, פריסת מס שבח, פטור ממס הכנסה על נכות רפואית, קיבוע זכויות בעת פרישה ועוד. ​
  המשרד מוכר כמייצג מורשה אל מול רשויות המס בישראל. ​
  משרדנו הינו בבעלות יועצת מס מוסמכת, בעלת ניסיון רב בתחום החזרי מס לשכירים. 
  כמו כן, המשרד חרט על דיגלו את ערכי ההגינות, מקצועיות ושירותיות מתוך הבנה כי מאחורי המספרים ישנם לבסוף אנשים אשר צרכיהם וחלומותיהם בידנו. ​`,
};
const whyUs = {
  title: "למה אנחנו?",
  description: {
    base: "ברטרו פלוס תקבלו יחס אישי, מהיר ומקצועי לפנייתכם. כמו כן, הפרטים שלכם נשמרים אצלנו בבטחה וניתן לחדש את הבקשה מדי שנה.",
    price: {
      title: "עלויות כספיות",
      description: `משרדנו אינו גובה עמלה לפתיחת תיק,
      רק במידה ויש החזר - יש עמלה
      לא קיבלת לא שילמת!!!  
      כמו כן התשלום בעת קבלת הכסף ממס הכנסה. 
      `,
    },
    birocratic: {
      title: "אפס בירוקרטיה",
      description: `כמייצגים מורשים של מס הכנסה ננפיק בשמכם את המסמכים.  
        כך נחסוך לכם התרוצצויות מיותרות למעסיקים, חברות ביטוח, ביטוח לאומי ועוד.
        `,
    },
    professionalism: {
      title: "מקצועיות",
      reasons: [
        "יועצי מס מומחים בתחום החזר מס.",
        "מתחייבים להחזר הגבוה ביותר.",
        "זיהוי בעיות העלולות לגרום לחובות.",
        "בדיקת כל הקריטריונים.",
        "הוגנות.",
      ],
    },
    personalAccompaniment: {
      title: "ליווי אישי",
      description: `מבדיקת הזכאות ועד קבלת ההחזר - המומחים שלנו ילוו אתכם לאורך התהליך. 
      תמיד תוכלו לדבר עם המומחים שלנו בערוצים השונים.
      `,
    },
  },
};

const eligibilityReasons = {
  title: "הסיבות לזכאות:​",
  description: `החלפות עבודה |  משיכת קרן פנסיה | תרומות לעמותות | תשלום מזונות | ילד שמקבלים עברו קצבת נכות | ילד שעבר ועדת השמה | הפקדות לביטוח חיים | תואר אקדמי | השקעה בנירות ערך בבורסה | מס שבח | מגורים בישוב מזכה | שחרור מהצבא | עולה חדש | העברות זיכויים עבור הפקדה לפנסיה | נכות מעל 89%.     ​
  ועוד עשרות זכאויות מורכבות על פי חוקי מס הכנסה.​`,
};

const whatWeDo = {
  title: "מה אנחנו עושים?",
  description: `המטרה שלנו היא לבצע את כל הפעולות האפשריות, במסגרת החוק כמובן,
  על מנת להשיג עבור לקוחותנו את החזר המס המקסימאלי שמגיע להם.​
  הצוות שלנו כולל את אנשי המקצוע הטובים ביותר. ​
  יועצי המס שלנו בעלי התמחות בתחום החזרי המס, בעלי ידע וניסיון רב בעבודה מול רשות המיסים.  ​
  ההצלחות שלנו נובעות בעיקר מהניסיון שצברנו והמומחיות שפיתחנו בטיפול בשכירים. ​
  יועצי המס שלנו מקושרים למחשבי רשות המיסים, דבר המקנה לנו יתרון ומאפשר לנו לעקוב מקרוב אחר כל שלבי התהליך עד להעברת הכסף מרשויות המס לחשבון הבנק שלך.`,
};

const retroTaxDetails = {
  title: "החזרי מס - חשוב לדעת",
  description: `כמיליון שכירים בישראל זכאים להחזר מס,
  אולי גם לך מגיע? ​
  אף אחד לא יתקשר לספר לך את זה!!! ​
  בזכות הידע והניסיון שלנו, אתם תקבלו את הכספים ישירות מקופת המדינה לחשבון הבנק שלכם, בתוספת ריבית והצמדה.​`,
};

const howItWorks = {
  title: "איך זה עובד?​",
  description: `לא עוד להשיג טפסים ממעסיקים ומביטוח לאומי, בלי לרדוף אחרי חברות ביטוח. ​
  ברטרו פלוס אנחנו דואגים לכל אלו ועוד הרבה יותר.​`,
};
export const HomePage = () => {
  const [, setPathnameLocation] = usePathnameLocation();
  const classes = useStyles();
  const historyLocation = useHistory();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleChange = (value) => {
    setPathnameLocation(value);
    historyLocation.push(value);
  };

  return (
    <div className={classes.main}>
      <Box className={classes.background1}>
        <Together className={classes.together} />
        <Container
          className={`${classes.textTitle} ${classes.title}`}
          maxWidth="md"
        >
          <Typography variant="h2" className={classes.firstTitle}>
            <b>{isXsScreen ? xsTitle : title}</b>
          </Typography>
          <Box className={classes.subTitle}>
            <Typography style={{ fontSize: "2.1rem" }}>
              <b>
                הבית שלך להחזר מס <span style={{ color: "black" }}>רטרו</span>{" "}
                עם הרבה <span style={{ color: "black" }}>פלוס</span>
              </b>
            </Typography>
          </Box>
        </Container>
        <Container
          className={classes.content}
          style={{ marginTop: "3vh" }}
          component="div"
          maxWidth="sm"
        >
          <Box className={classes.topic}>
            <Typography variant="h4" className={classes.textTitle}>
              <b>{whoIsUs.title}</b>
            </Typography>
            <Typography variant="body1">{whoIsUs.description}</Typography>
          </Box>
        </Container>
      </Box>
      <Box className={classes.background2}>
        <Container
          className={classes.content}
          style={{ marginTop: "3vh" }}
          component="div"
          maxWidth="sm"
        >
          <Box className={classes.topic}>
            <Typography variant="h4" className={classes.textTitle}>
              <b>{whyUs.title}</b>
            </Typography>
            <Typography variant="body1">{whyUs.description.base}</Typography>
          </Box>
        </Container>
        <CustomCard>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item md={3} sm={12}>
              <Price className={classes.icon} />
              <Typography variant="h5">
                <b>{whyUs.description.price.title}</b>
              </Typography>
              <Typography variant="body1">
                {whyUs.description.price.description}
              </Typography>
            </Grid>
            <Grid item md={3} sm={12}>
              <Birocratic className={classes.icon} />
              <Typography variant="h5">
                <b>{whyUs.description.birocratic.title}</b>
              </Typography>
              <Typography variant="body1">
                {whyUs.description.birocratic.description}
              </Typography>
            </Grid>
            <Grid item md={3} sm={12}>
              <Suticase className={classes.icon} />
              <Typography variant="h5">
                <b>{whyUs.description.professionalism.title}</b>
              </Typography>
              <ul style={{ margin: 0, display: "inline-block" }}>
                {whyUs.description.professionalism.reasons.map(
                  (reason, index) => (
                    <li key={index}>
                      <Typography
                        variant="body1"
                        style={{ textAlign: "right" }}
                      >
                        {reason}
                      </Typography>
                    </li>
                  )
                )}
              </ul>
            </Grid>
            <Grid item md={3} sm={12}>
              <Attention className={classes.icon} />
              <Typography variant="h5">
                <b>{whyUs.description.personalAccompaniment.title}</b>
              </Typography>
              <Typography variant="body1">
                {whyUs.description.personalAccompaniment.description}
              </Typography>
            </Grid>
          </Grid>
        </CustomCard>
        <Container className={classes.content} component="div" maxWidth="sm">
          <Button
            color="primary"
            className={classes.button}
            onClick={() => handleChange(Routes.contentArticle)}
            variant="contained"
          >
            <Typography variant="h4">
              <b>למה שלא אבדוק לבד?</b>
            </Typography>
          </Button>
          <Box className={classes.topic}>
            <Typography variant="h4" className={classes.textTitle}>
              <b>{whatWeDo.title}</b>
            </Typography>
            <Typography variant="body1">{whatWeDo.description}</Typography>
          </Box>
          <Box className={classes.topic}>
            <Typography variant="h4" className={classes.textTitle}>
              <b>{retroTaxDetails.title}</b>
            </Typography>
            <CustomCard className={classes.ellipse}>
              <Typography variant="body1">
                {retroTaxDetails.description}
              </Typography>
            </CustomCard>
          </Box>
          <Button
            onClick={() => handleChange(Routes.contactUs)}
            className={classes.button}
            color="primary"
            variant="contained"
          >
            <Typography variant="h4">
              <b>צרו איתי קשר</b>
            </Typography>
          </Button>
          <Box className={classes.topic}>
            <Typography variant="h4" className={classes.textTitle}>
              <b>{eligibilityReasons.title}</b>
            </Typography>
            <Typography variant="body1">
              {eligibilityReasons.description}
            </Typography>
          </Box>
          <Box className={classes.topic}>
            <Typography variant="h4" className={classes.textTitle}>
              <b>{howItWorks.title}</b>
            </Typography>
            <Typography variant="body1">{howItWorks.description}</Typography>
          </Box>
        </Container>
      </Box>
      <Box className={classes.background2}>
        <ReturnProcessDescriptions />
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  background1: {
    backgroundImage: `url(${backgroundImage1})`,
    backgroundSize: "cover",
    marginTop: "-15vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-50vh",
      paddingTop: "50vh",
    },
    paddingTop: "15vh",
    paddingBottom: "3vh",
  },
  background2: {
    backgroundImage: `url(${backgroundImage2})`,
    backgroundSize: "cover",
    paddingBottom: "3vh",
    marginTop: "-3vh",
    paddingTop: "3vh",
  },
  main: {
    whiteSpace: "pre-wrap",
  },
  content: {
    textAlign: "center",
  },
  firstTitle: {
    padding: "1vh",
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: "3vw",
      paddingLeft: "10vh",
    },
  },
  title: {
    textAlign: "center",
    marginTop: "15vh",
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "20vw",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: "10vw",
      marginTop: "45vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "72vw",
      paddingLeft: "2vh",
    },
  },
  subTitle: {
    marginTop: "4vh",
    padding: "2vh 0vw 1vh 7vw",
    [theme.breakpoints.down("xs")]: {
      padding: "2vh 4vw 1vh 4vw",
    },
  },
  textTitle: {
    color: theme.palette.primary.main,
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
  },
  card: {
    margin: "5vh 1vw 5vh 1vw",
    padding: 10,
    textAlign: "center",
    backgroundColor: theme.palette.primary.light,
    border: "solid black",
    borderRadius: 25,
  },
  icon: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    marginBottom: "1em",
  },
  together: {
    position: "absolute",
    top: "16vh",
    left: "4vw",
    width: "55vh",
    zIndex: "auto",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "45vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60vw",
    },
  },
  button: {
    margin: "4vh",
    padding: "1rem",
  },
  ellipse: {
    margin: "5vh 1vw 5vh 1vw",
    textAlign: "center",
    backgroundColor: theme.palette.primary.light,
    border: "solid black",
    borderRadius: "50%",
    padding: "55px 90px 55px 90px",
  },
  topic: {
    margin: "3vh",
  },
}));
