import { EligibilityCheckQuestions } from "../components/eligibility-check-questions/eligibility-check-questions";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ManWithLaptop } from "../images";
import { TopicContent } from "../components/custom-components";
import backgroundImage1 from "../images/background-picture2.jpg";

const details = {
  title: "האם מגיע לי החזר מס?",
  description: `בדקו זכאותכם במחשבון הזכאות שלנו, ​
  חמש שאלות קצרות ונדע האם יתכן ומגיע לכם החזר מס מהמדינה.​`,
};
export const EligibilityCheck = () => {
  const classes = useStyles();

  return (
    <div className={classes.background1}>
      <Container className={classes.main} component="div" maxWidth="sm">
        <ManWithLaptop className={classes.manWithLaptop} />
        <Typography
          className={`${classes.title} ${classes.textTitle}`}
          variant="h2"
        >
          <b>{`בדיקת זכאות\nלהחזר מס`}</b>
        </Typography>
        <TopicContent title={details.title} description={details.description} />
        <EligibilityCheckQuestions />
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    whiteSpace: "pre-wrap",
    textAlign: "center",
    marginBottom: "1vh",
  },
  background1: {
    backgroundImage: `linear-gradient(rgb(255 255 255 / 80%), rgb(255 255 255 / 80%)), url(${backgroundImage1})`,
    backgroundSize: "cover",
    minHeight: "calc(100vh - 65px)",
    paddingTop: "14vh",
  },
  manWithLaptop: {
    position: "absolute",
    top: "16vh",
    left: "4vw",
    width: "55vh",
    zIndex: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "60vw",
    },
  },
  title: {
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "20vw",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "35vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "50vw",
    },
  },
  textTitle: {
    color: theme.palette.primary.main,
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
  },
}));
