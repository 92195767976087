import * as React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./style.css";

export const Carousel = ({ items }) => {
  return (
    <AliceCarousel
      autoPlay
      autoPlayStrategy="none"
      autoPlayInterval={5000}
      animationType="fadeout"
      animationDuration={1000}
      infinite
      touchTracking={false}
      disableDotsControls
      disableButtonsControls
      disableSlideInfo
      items={items}
    />
  );
};
