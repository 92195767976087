import { PaperCircle } from "../paper-circle";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import { Typography } from "@material-ui/core";
import { Arrow2 as Arrow } from "../../images";

export const UpSmScreen = ({ step1, step2, step3, step4, step5, step6 }) => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box className={classes.box1}>
        <PaperCircle size={"16vw"}>
          <Typography variant="body1" className={classes.text}>
            {step1}
          </Typography>
        </PaperCircle>
        <Arrow className={classes.arrow1} />
        <PaperCircle size={"15.5vw"}>
          <Typography variant="body1" className={classes.text}>
            {step2}
          </Typography>
        </PaperCircle>
        <Arrow className={classes.arrow2} />
        <PaperCircle size={"18vw"}>
          <Typography variant="body1" className={classes.text}>
            {step3}
          </Typography>
        </PaperCircle>
      </Box>
      <Box className={classes.box2}>
        <Arrow className={classes.arrow3} />
        <Box className={classes.subBox2}>
          <PaperCircle size={"20vw"}>
            <Typography variant="body1" className={classes.text}>
              {step4}
            </Typography>
          </PaperCircle>
          <Arrow className={classes.arrow4} />
          <PaperCircle size={"16vw"}>
            <Typography variant="body1" className={classes.text}>
              {step5}
            </Typography>
          </PaperCircle>
        </Box>
      </Box>
      <Box className={classes.box3}>
        <Arrow className={classes.arrow5} />
      </Box>
      <Box className={classes.box4}>
        <PaperCircle size={"22vw"}>
          <Typography variant="h6" className={classes.text}>
            <b>{step6}</b>
          </Typography>
        </PaperCircle>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0vh 3vw 3vh 3vw",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "black",
    },
  },
  box1: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  box2: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    direction: "rtl",
    paddingRight: "18vw",
  },
  subBox2: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    direction: "rtl",
    padding: "1vh 14vw 0 0vw",
  },
  box3: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingLeft: "15vw",
  },
  box4: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },
  arrow1: {
    width: "20%",
    padding: "2vw",
    height: "100%",
    transform: "rotate(-180deg)",
  },
  arrow3: {
    transform: "rotate(-40deg)",
    width: "26%",
    padding: "3vw 3vw 2vw 2vw",
  },
  arrow2: {
    width: "20%",
    padding: "2vw",
    height: "100%",
    transform: "rotate(-180deg)",
  },
  arrow4: {
    width: "24%",
    margin: "2vw",
    height: "100%",
  },
  arrow5: {
    width: "22%",
    margin: "4vw 0vw 0vw 4vw",
    height: "100%",
    transform: "rotate(-140deg)",
  },
}));
