import { Carousel } from "../components/carousel";
import { CustomersRrecommendBox } from "../components/customers-recommend-box";
import { useEffect, useState } from "react";
import RecommendPublicServices from "../services/public-recommend";

export const CustomersRecommend = () => {
  const [customersRecommend, setCustomersRecommend] = useState([]);

  const fetch = async () => {
    try {
      setCustomersRecommend(await RecommendPublicServices.fetchAll());
    } catch {
      alert("יש שגיאה");
    }
  };

  useEffect(() => {
    (async () => await fetch())();
  }, []);

  return (
    <Carousel
      items={customersRecommend.map((customerRrecommend) => (
        <CustomersRrecommendBox
          key={customerRrecommend.id}
          customerRecommend={customerRrecommend}
        />
      ))}
    />
  );
};
