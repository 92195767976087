import { useContext } from "react";
import { createContext, useState } from "react";
import { Routes } from "../../App";

const Context = createContext();

export const PathnameLocationContext = ({ children }) => {
  const [pathnameLocation, setPathnameLocation] = useState(Routes.homePage);

  return (
    <Context.Provider value={[pathnameLocation, setPathnameLocation]}>
      {children}
    </Context.Provider>
  );
};

export const usePathnameLocation = () => {
  const [pathnameLocation, setPathnameLocation] = useContext(Context);

  return [pathnameLocation, setPathnameLocation];
};
