import { BaseBox } from "./base-box";

const answer = {
  title: `יתכן ומגיע לך החזר מס!!!`,
  description: `השאר פרטיך ונחזור אליך בהקדם.​
בדיקת הזכאות ללא עלות פתיחת תיק.`,
};

export const Eligibility = () => {
  return <BaseBox title={answer.title} description={answer.description} />;
};
